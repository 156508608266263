import { default as aboutmeKpSxz9HCstMeta } from "/vercel/path0/pages/aboutme.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as ProjectsGbNA0IbdR4Meta } from "/vercel/path0/pages/Projects.vue?macro=true";
export default [
  {
    name: "aboutme",
    path: "/aboutme",
    component: () => import("/vercel/path0/pages/aboutme.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "Projects",
    path: "/Projects",
    component: () => import("/vercel/path0/pages/Projects.vue")
  }
]